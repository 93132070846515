import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { MixpanelService } from 'src/app/core/services/mixpanel.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { UserProfile } from '../../classes';
import { AccountService } from '../../services/account.service';

@Component({
  selector: 'app-dashboard',
  animations: [
    trigger('openClose', [
      state('open', style({
        position: 'absolute',
        width: '75%',
        height: '100%',
        backgroundColor: 'white',
        zIndex: '10'
      })),
      state('closed', style({
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100%',
        width: '10%',
        zIndex:'-10',
        opacity: 0
      })),
      transition('open => closed', [
        animate('500ms ease-in')
      ]),
      transition('closed => open', [
        animate('300ms ease-in')
      ]),
    ]),
  ],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  profile!:UserProfile;
  hideMobileNav:boolean = false;
  isOpen = false;

  constructor(
    private accountService:AccountService, 
    private storageService:StorageService,
    private router: Router, 
    private authService:AuthService,
    private mixpanelService:MixpanelService) { }

  ngOnInit(): void {
    this.requestUserProfile();
  }
  
  requestUserProfile():void {
   
    if(this.profile === undefined) {
      
      this.accountService.getUserProfile().subscribe({
        next: data => {
          this.profile = new UserProfile(
            data.id,
            data.user_id,
            data.email,
            data.premium_enabled,
            data.first_name,
            data.attribute_profile.birth_date,
          );
          // This will enable mixpanel to map events to
          // a specific user profile
          this.mixpanelService.init(this.profile.email);
          this.accountService.setUserProfileValues = this.profile;
        },
        error: err => {
          console.log(err);
        }
      })
    }
  }
  
  logout(): void {
    this.authService.logout().subscribe({
      next: data => {
        this.router.navigate(["/auth/login"]);
        this.authService.removeToken();
      },
      error: err => {
        this.authService.removeToken();
      }
    });
    
  }


  toggleMobileSideNav(): void {
    this.hideMobileNav  = !this.hideMobileNav;
    this.isOpen  = !this.isOpen;
  }
}
