<div class="app-form-question" [style.flex-direction]="getFormDirectionStyle()">
    <button 
        *ngFor="let option of formItems; let elementIndex = index"
        [class]="{'multi-select-button': true, selected:isOptionSelected(elementIndex)}"
        [attr.elementIndex]="elementIndex"
        (click)="toggleSelection(elementIndex)"
    >
        {{option.viewValue}}
    </button>
</div>
    
