import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatChipsModule} from '@angular/material/chips';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDividerModule} from '@angular/material/divider';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTableModule} from '@angular/material/table';

import * as fromComponents from './components';


@NgModule({
  declarations: [
    ...fromComponents.components,
  ],
  imports: [
    CommonModule, FormsModule, MatSelectModule, MatProgressBarModule,
    MatChipsModule, MatButtonModule
  ],
  exports: [
    CommonModule, FormsModule, ReactiveFormsModule,  MatIconModule, 
    MatInputModule, MatButtonModule, MatCheckboxModule, MatMenuModule,
    MatCardModule, MatFormFieldModule, MatSnackBarModule, MatExpansionModule,
    MatDatepickerModule, MatDividerModule, MatToolbarModule, MatTableModule,
    ...fromComponents.components
  ]
})
export class SharedModule { }
