<div class="datepicker-component">
  <form class="account-details-fields">
    <div class="date-birth-fields">
        <select class="select-birth-date" [ngModel]="selectedMonthValue" #monthItem (ngModelChange)="handleMonthChangeEvent(monthItem.value)" name="month">
            <option  disabled value="Month">
                Month
            </option>
            <option *ngFor="let month of months" [value]="month.value"> 
                {{month.viewValue}} </option>
        </select>
        <select class="select-birth-date" [ngModel]="selectedYearValue" #yearItem (ngModelChange)="handleYearChangeEvent(yearItem.value)" name="year">
            <option  disabled value="Year">
                Year
            </option>
            <option *ngFor="let year of years" [value]="year.value"> 
                {{year.viewValue}} 
            </option>
        </select>
    </div>
  </form>
<!-- <p> Child date: {{selectedMonthValue}}-{{selectedYearValue}} </p> -->
</div>