import { LoginComponent } from "./login/login.component";
import { RegistrationComponent } from "./registration/registration.component";
import { ResetPasswordConfirmComponent } from "./reset-password-confirm/reset-password-confirm.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";

export const components: any[] = [RegistrationComponent, LoginComponent, ResetPasswordComponent, ResetPasswordConfirmComponent];

export * from './registration/registration.component';
export * from "./login/login.component";
export * from "./reset-password/reset-password.component";
export * from "./reset-password-confirm/reset-password-confirm.component";
