import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-download-apps',
  templateUrl: './download-apps.component.html',
  styleUrls: ['./download-apps.component.scss']
})
export class DownloadAppsComponent implements OnInit {

  @Input() downloadHeader:string = "Download MBODY Now!";
  @Input() downloadMessage:string = "Understand your hormones. Enhance your health. Increase your happiness.";

  constructor() { }

  ngOnInit(): void {
  }

}
