import { Injectable } from '@angular/core';

const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor() {}

  public clean(): void {
    localStorage.clear();
  }

  public get(key: string) {
    return localStorage.getItem(key);
  }

  public set(key: string, value: any) {
    localStorage.setItem(key, value);
  }

  public remove(key: string) {
    localStorage.removeItem(key);
  }

  public clearReferralKeys() {
    this.remove("stripe_code");
    this.remove("flow");
    this.remove("gp");
    this.remove("stripe_campaign");
    this.remove("stripe_promo_code");
  }

}