<div class="login-wrapper"> 

    <div class="login-body">
    
        <mat-card class="login-card">
            <mat-card-header>
              <mat-card-title> <span>MBODY</span> Login</mat-card-title>
            </mat-card-header>

            <mat-card-content class="login-fields">
                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input id="user-text-field" autocomplete="username" matInput [formControl]="emailFormControl" type="email">

                    <mat-error *ngIf="emailFormControl.hasError('required')">
                        Email is <strong>required</strong>
                    </mat-error>

                    <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                        Please enter a valid email address
                    </mat-error>
                      
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input id="password-text-field" autocomplete="current-password" matInput [formControl]="passwordFormControl" [type]="passwordHide ? 'password' : 'text'">
                    <button mat-icon-button matSuffix (click)="passwordHide = !passwordHide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passwordHide">
                      <mat-icon>{{passwordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-error *ngIf="passwordFormControl.hasError('required')">
                        Password is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="passwordFormControl.hasError('minlength')">
                        Password is <strong>less than {{passwordFormControl.getError('minlength')["requiredLength"]}} characters</strong>
                    </mat-error>
                    <mat-error *ngIf="passwordFormControl.hasError('minlength')">
                        {{ passwordFormControl.getError('minlength')["requiredLength"] - passwordFormControl.getError('minlength')["actualLength"] }}<strong> characters left </strong>
                    </mat-error>
                </mat-form-field>

            </mat-card-content>

            <button mat-raised-button color="primary" (click)="logIn()">Log in</button>
            
            <div class="login-footer">
                
                <div class="footer-redirect">
                    <p>Not signed up yet?</p>
                    <a routerLink="../registration"> <h4> Sign up now</h4>  </a>
                </div>

                <div class="footer-links">
                    <a routerLink="../reset-password"> <h5> Forget password |</h5>  </a>
                    <a href="https://www.mbody.health/privacy" > <h5> Privacy Policy |</h5>  </a>
                    <a href="https://www.mbody.health/help" > <h5> Contact us </h5>  </a>
                </div>
            </div>

        </mat-card>
    </div>

</div>