<div class="download-apps-wrapper">
    <div class='download-content'>
        <h2 class="pb-2">{{ downloadHeader }}</h2>
        <div class="download-message">
            
              {{ downloadMessage}}
            
        </div>
        
    </div>
    <div class="store-icons">
        <a href="https://apps.apple.com/gb/app/mbody-womens-hormone-health/id1587020838" target="_blank" rel="noopener noreferrer">
          <img class="store-img" src="../../../../assets/images/logo/app_store.svg">
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.baby2bodylimited.mbody"
        target="_blank" rel="noopener noreferrer">
          <img class="store-img" src="../../../../assets/images/logo/google_play.svg">
        </a>
    </div>
</div>