export const Symptoms = {
    "Allergies": {
      value:"Allergies",
      viewValue:"Allergies", 
      relatedSymptoms:[
        "Headaches", "Poor sleep", "Fatigue", "Dizziness",
        "Brain fog", "Bloating and digestive troubles","Incontinence",
        "Breast soreness", "Joint pain", "Restless legs",
        "Feeling low", "Feeling irritable", "Anxiety", "Skin changes",
        "Thinning hair", "Low libido", "Muscle pains"
      ]
    },
    "Headaches": {
      value:"Headaches",
      viewValue:"Headaches", 
      relatedSymptoms:[
        "Allergies", "Poor sleep", "Fatigue", "Dizziness",
        "Racy heart","Brain fog", "Bloating and digestive troubles",
        "Feeling low", "Feeling irritable", "Anxiety", "Low libido"
      ]
    },
    "Poor sleep": {
      value:"Poor sleep",
      viewValue:"Poor sleep", 
      relatedSymptoms:[
        "Restless legs", "Headaches", "Feeling low", "Feeling irritable",
        "Anxiety", "Fatigue", "Hot flashes", "Dizziness",
        "Allergies", "Muscle pains","Incontinence","Low libido",
        "Bloating and digestive troubles", 
      ]
    },
    "Fatigue": {
      value:"Fatigue",
      viewValue:"Fatigue", 
      relatedSymptoms:[
        "Allergies", "Headaches", "Poor sleep", "Dizziness",
        "Brain fog", "Feeling low", "Feeling irritable", "Anxiety",
        "Low libido", "Racy heart"
      ]
    },
    "Dizziness": {
      value:"Dizziness",
      viewValue:"Dizziness", 
      relatedSymptoms:[
        "Headaches", "Poor sleep", "Hot flashes", "Fatigue",
        "Brain fog", "Electric shocks", "Allergies", "Racy heart", 
        "Tingling extremities", "Bloating and digestive troubles",
        "Incontinence",
      ]
    },
    "Brain fog": {
      value:"Brain fog",
      viewValue:"Brain fog", 
      relatedSymptoms:[
        "Headaches", "Fatigue", "Dizziness", "Allergies", 
        "Electric shocks", "Bloating and digestive troubles",
        "Tingling extremities","Joint pain","Feeling low",
        "Anxiety","Muscle pains"
      ]
    },
    "Bloating and digestive troubles": {
      value:"Bloating and digestive troubles",
      viewValue:"Bloating and digestive troubles", 
      relatedSymptoms:[
        "Breast soreness", "Joint pain", "Headaches", "Poor sleep", 
        "Body odor changes", "Dizziness", "Brain fog", "Incontinence",
        "Allergies", "Racy heart", "Low libido"
      ]
    },
    "Breast soreness": {
      value:"Breast soreness",
      viewValue:"Breast soreness", 
      relatedSymptoms:[
        "Allergies", "Bloating and digestive troubles",
        "Joint pain","Feeling irritable"
      ]
    },
    "Joint pain": {
      value:"Joint pain",
      viewValue:"Joint pain", 
      relatedSymptoms:[
        "Thinning hair", "Restless legs", "Skin changes", 
        "Muscle pains", "Tingling extremities", "Breast soreness", "Brain fog",
        "Bloating and digestive troubles", "Incontinence", "Allergies", "Low libido", 
      ]
    },
    "Restless legs": {
      value:"Restless legs",
      viewValue:"Restless legs", 
      relatedSymptoms:[
        "Joint pain", "Poor sleep","Feeling irritable",
        "Anxiety", "Muscle pains", "Racy heart", 
        "Electric shocks", "Tingling extremities", "Allergies"
      ]
    },
    "Feeling low": {
      value:"Feeling low",
      viewValue:"Feeling low", 
      relatedSymptoms:[
          "Headaches", "Poor sleep", "Feeling irritable", "Fatigue",
          "Body odor changes", "Hot flashes", "Skin changes", "Anxiety",
          "Thinning hair","Brain fog", "Low libido", "Allergies"
      ]
    },
    "Feeling irritable": {
      value:"Feeling irritable",
      viewValue:"Feeling irritable", 
      relatedSymptoms:[
        "Breast soreness","Headaches","Restless legs", "Poor sleep", 
        "Feeling low", "Hot flashes", "Fatigue", "Racy heart", "Allergies"
      ]
    },
    "Anxiety": {
      value:"Anxiety",
      viewValue:"Anxiety", 
      relatedSymptoms:[
        "Headaches", "Restless legs", "Poor sleep", "Feeling low",
        "Hot flashes", "Fatigue", "Low libido", "Brain fog", 
        "Bloating and digestive troubles","Incontinence", 
        "Racy heart", "Allergies"
      ]
    },
    "Hot flashes": {
      value:"Hot flashes",
      viewValue:"Hot flashes", 
      relatedSymptoms:[
        "Poor sleep", "Feeling low", "Feeling irritable", "Anxiety",
        "Body odor changes", "Dizziness", "Electric shocks", "Racy heart"
      ]
    },
    "Body odor changes": {
      value:"Body odor changes",
      viewValue:"Body odor changes", 
      relatedSymptoms:[
       "Feeling low", "Hot flashes", "Skin changes",
       "Low libido", "Bloating and digestive troubles"
      ]
    },
    "Skin changes": {
      value:"Skin changes",
      viewValue:"Skin changes", 
      relatedSymptoms:[
       "Body odor changes", "Joint pain", "Feeling low", "Low libido", "Thinning hair", "Incontinence", "Allergies"
      ]
    },
    "Thinning hair": {
      value:"Thinning hair",
      viewValue:"Thinning hair", 
      relatedSymptoms:[
       "Joint pain", "Feeling low", "Skin changes", "Low libido", "Allergies"
      ]
    },
    "Electric shocks": {
      value:"Electric shocks",
      viewValue:"Electric shocks",
      relatedSymptoms:[
        "Restless legs", "Hot flashes", "Dizziness", "Brain fog"
      ]
    },
    "Incontinence": {
      value:"Incontinence",
      viewValue:"Incontinence",
      relatedSymptoms:[
       "Bloating and digestive troubles", "Anxiety", "Joint pain", "Low libido",
       "Poor sleep", "Skin changes", "Dizziness", "Allergies" 
      ]
    },
    "Low libido": {
      value:"Low libido",
      viewValue:"Low libido",
      relatedSymptoms:[
        "Allergies", "Joint pain", "Headaches", "Poor sleep", "Feeling low", 
        "Anxiety", "Body odor changes", "Skin changes", "Fatigue",
        "Thinning hair", "Incontinence"
      ]
    },
    "Racy heart": {
      value:"Racy heart",
      viewValue:"Racy heart",
      relatedSymptoms:[
        "Headaches","Restless legs", "Feeling irritable", "Anxiety",
        "Hot flashes", "Fatigue", "Dizziness", "Bloating and digestive troubles"
      ]
    },
    "Tingling extremities": {
      value:"Tingling extremities",
      viewValue:"Tingling extremities",
      relatedSymptoms:[
       "Joint pain", "Restless legs", "Dizziness", "Brain fog"
      ]
    },
    "Muscle pains": {
      value:"Muscle pains",
      viewValue:"Muscle pains",
      relatedSymptoms:[
       "Joint pain", "Restless legs", "Poor sleep", "Brain fog", "Allergies"
      ]
    },
}