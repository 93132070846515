import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class HeaderTokenInterceptor implements HttpInterceptor {

  constructor(private authService:AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if ( this.authService.isLoggedIn() ){
      const token = this.authService.getToken();
      const auth = request.clone({ headers: new HttpHeaders().set(`Authorization`, `Token ${token}`) });
      return next.handle(auth);
    }
    return next.handle(request);
  }
}
