import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {

  constructor() {
    mixpanel.init(environment.mixpanelToken, {debug: environment.mixpanelDebugMode});
   }

  init(userIdentifier: string): void {
    mixpanel.identify(userIdentifier);
  }

  setUserProperties(userProperties: any = {}): void {
    mixpanel.people.set(userProperties);
  }

  track(eventName: string, eventProperties: any = {}): void {
    mixpanel.track(eventName, eventProperties);
  }
}
