import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { environment } from 'src/environments/environment';
import { UserProfile } from '../classes';

const API = `${environment.baseURL}/api/`
const AUTH_API = `${environment.baseURL}/rest-auth/`

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  userProfileSubject = new BehaviorSubject<UserProfile>(new UserProfile(-1,-1,"",false,"",""))

  constructor(
    private storageService:StorageService,
    private authService: AuthService,
    private http: HttpClient) { }

  getUserProfile(): Observable<any> {
    // Initialize Params Object
    let params = new HttpParams();
    params = params.append('format', "json");
    params = params.append('log', 1);

    return this.http.get(
      API + 'v/3/profile/',
      {
        params:params
      }
    );
  }

  getUserCountry(): Observable<any> {

    return this.http.get(
      API + 'v/1/profile/country'
    );
  }

  updateUserEmailAddress(email:string|null): Observable<any> {
    // Initialize Params Object
    let params = new HttpParams();
    params = params.append('format', "json");

    return this.http.put(
      API + 'v/3/profile/',
      {
        "email":email
      },
      {
        params:params
      }
    );
  }

  changeUserPassword(old_password:string|null, new_password1:string|null, new_password2:string|null): Observable<any> {
    return this.http.post(
      AUTH_API + 'password/change/',
      {
        "old_password":old_password,
        "new_password1":new_password1,
        "new_password2":new_password2,
      }
    );
  }

  public get userProfileValues(){
    console.log("get profile called")
    return this.userProfileSubject
  }

  public set setUserProfileValues(userProfile:UserProfile){
    console.log("set profile called")
    this.userProfileSubject.next(userProfile);
  }

}
