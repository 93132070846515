<script type="module" src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.esm.js"></script>

<div class="onboarding-page"> 
    <div class="onboarding-page-header">
        <div> 
            <button 
                (click)="navigateToPreviousForm(getSkipPreviousStep())" 
                mat-icon-button 
                [disabled]="isPreviousButtonDisabled"
                aria-label="Back button"
            >
                <mat-icon>arrow_back_ios</mat-icon>
            </button>
        </div>
        
        <div class="onboarding-progress-bar">
            <app-progress-bar [progressBarValue]="progressBarValue"></app-progress-bar>
        </div>
    </div>

    <div class="wrapper">
        <div class="form_wrap">
            <div *ngIf="forms[1].active" [style.display]="forms[1].display">
                <div class="form-title"> <h2> {{forms[1].title}} </h2> </div>
                <div class="form-body"> 
                    <div class="activation-code">
                        <input 
                            class="custom-activation-input" maxlength="9" type="text" 
                            placeholder="Activation Code"
                            [ngModel]="activationCode" (ngModelChange)="activationCode = $event.toUpperCase()"
                        >
                        <button mat-button *ngIf="isVerifyingCode" class="custom-activation-button" (click)="validateActivationCodeForm()">
                            Verifying...
                        </button>
                        <button mat-button *ngIf="!isVerifyingCode" class="custom-activation-button" (click)="validateActivationCodeForm()">Go</button>
                    </div>
                </div>
            </div>
            <div [style.display]="forms[2].display">
                <div class="form-title"> <h2> {{forms[2].title}} </h2> </div>
                <div class="form-body"> 
                    <app-date-picker (yearChangeEvent)="handleYear($event)" (monthChangeEvent)="handleMonth($event)"></app-date-picker>
                </div>
            </div>
            <div [style.display]="forms[3].display">
                <div class="form-title"> <h2> {{forms[3].title}} </h2> </div>
                <div class="form-body"> 
                    <app-form-question 
                        [formItems]="getformItems(3)" 
                        [formSelectionType]="getFormType(3)" 
                        [formStyle]="getformStyles(3)" 
                        (selectionChangeEvent)="handlePeriod($event)"
                    >
                    </app-form-question>
                </div>
            </div>
            <div [style.display]="forms[4].display">
                <div class="form-title"> <h2> {{forms[4].title}} </h2> </div>
                <div class="form-body">
                    <app-form-question 
                        [formItems]="getformItems(4)" 
                        [formSelectionType]="getFormType(4)" 
                        [formStyle]="getformStyles(4)" 
                        (selectionChangeEvent)="handleHormonalContraceptives($event)"
                    > 
                    </app-form-question>
                </div>
            </div>
            <div [style.display]="forms[5].display">
                <div class="form-title"> <h2> {{forms[5].title}} </h2> </div>
                <div class="form-body">
                    <app-form-question 
                        [formItems]="getformItems(5)"
                        [formSelectionType]="getFormType(5)" 
                        [formStyle]="getformStyles(5)"
                        (selectionChangeEvent)="handleMostImpactfulSymptom($event)"
                    > 
                    </app-form-question>
                </div>
            </div>
            <div [style.display]="forms[6].display">
                <div class="form-title"> <h2> {{forms[6].title}} </h2> </div>
                <div class="form-body">
                    <app-form-question 
                        [formItems]="relatedSymptoms"
                        [formSelectionType]="getFormType(6)"
                        [formStyle]="getformStyles(6)"
                        (selectionChangeEvent)="handleRelatedSymptoms($event)"
                    > 
                    </app-form-question>
                </div>
            </div>
            <div [style.display]="forms[7].display">
                <div class="form-title"> <h2> {{forms[7].title}} </h2> </div>
                <div class="form-body">
                    <app-form-question 
                        [formItems]="getformItems(7)"
                        [formSelectionType]="getFormType(7)"
                        [formStyle]="getformStyles(7)"
                        (selectionChangeEvent)="handleSymptomsManagement($event)"
                    > 
                    </app-form-question>
                </div>
            </div>
            <div [style.display]="forms[8].display">
                <div class="form-title"> <h2> {{forms[8].title}} </h2> </div>
                <div class="form-body"> 
                    <app-form-question 
                        [formItems]="getformItems(8)" 
                        [formSelectionType]="getFormType(8)" 
                        [formStyle]="getformStyles(8)"
                        (selectionChangeEvent)="handleSupportInterests($event)"
                    > 
                    </app-form-question>
                </div>
            </div>
            <div [style.display]="forms[9].display">
                <div class="form-title"> <h2> {{forms[9].title}} </h2> </div>
                <div class="form-body"> 
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Name</mat-label>
                        <input type="name" matInput [formControl]="nameFormControl" (ngModelChange)="handleName($event)" placeholder="Your Name">
                        <mat-error *ngIf="nameFormControl.hasError('required')">
                          Name is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="nameFormControl.hasError('minlength')">
                            Name is <strong> less than {{nameFormControl.getError('minlength')["requiredLength"]}} characters</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Email</mat-label>
                        <input type="email" matInput [formControl]="emailFormControl"  (ngModelChange)="handleEmail($event)" placeholder="example@mbody.health">
                        <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                          Please enter a valid email address
                        </mat-error>
                        <mat-error *ngIf="emailFormControl.hasError('required')">
                          Email is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Password</mat-label>
                        <input id="new-password-text-field" autocomplete="new-password" type="password" matInput [formControl]="passwordFormControl" (ngModelChange)="handlePassword($event)" placeholder="Password">
                        <mat-error *ngIf="passwordFormControl.hasError('required')">
                          Password is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="passwordFormControl.hasError('minlength')">
                            Password is <strong>less than {{passwordFormControl.getError('minlength')["requiredLength"]}} characters</strong>
                        </mat-error>
                        <mat-error *ngIf="passwordFormControl.hasError('minlength')">
                            {{ passwordFormControl.getError('minlength')["requiredLength"] - passwordFormControl.getError('minlength')["actualLength"] }}<strong> characters left </strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="btns_wrap">
            <div class="common_btns" [style.display]="isNextActive()">
                <button mat-raised-button  [disabled]="isFormValid()" color="primary" (click)="navigateToNextForm()">Next</button>
            </div>
            <div class="common_btns" [style.display]="isSkipActive()">
                <h3 (click)="navigateToNextForm(getSkipStep())">{{getSkipText()}}</h3>
            </div>
            <div class="common_btns" [style.display]="isFormActive(9)">
                <button mat-raised-button [disabled]="isFormValid()" color="primary" (click)="submitForm()" >Sign up</button>
            </div>
        </div>
        <!-- {{ formValues | json }} -->
    </div>

</div>

