import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { SnackBarMessageComponent } from 'src/app/shared/components';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  passwordHide = true;
  successMessage!:string;
  emailFormControl = new FormControl('', [Validators.required, Validators.email]);
  
  constructor(private authService:AuthService, 
              private router: Router,
              private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  resetPassword(): void {

    if (this.isEmptyFields()) {
      this.displayMessageSnackBar("Please fill the required fields above", "error");
    }
    else if (this.isValidFields()) {
      let email = this.emailFormControl.value;

      this.authService.resetPassword(email).subscribe({
        next: data => {
          this.successMessage = "A reset link has been sent to your email, please check your inbox (spam) for further instructions."
          this.displayMessageSnackBar("Your request has been submitted", "success");
        },
        error: res => {
          this.displayMessageSnackBar("Issue processing the request. Please try again!", "error");
        }
      });
    }
    else {
      this.displayMessageSnackBar("Please correct the above fields", "error");
    }

  }

  isValidFields():boolean {
    let emailStatus = this.emailFormControl.status === "VALID" ? true : false;

    return emailStatus
  }

  isEmptyFields():boolean {
    let emailValue = this.emailFormControl.value === "" ? true : false;
    return emailValue
  }

  displayMessageSnackBar(textMessage:string, textColorType:string) {
    this.snackBar.openFromComponent(SnackBarMessageComponent, {duration: 5000, data:{textMessage, textColorType}});
  }
}
