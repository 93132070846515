import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack-bar-message',
  templateUrl: './snack-bar-message.component.html',
  styleUrls: ['./snack-bar-message.component.scss']
})
export class SnackBarMessageComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public message: any) { }

  textColor:string = "red";
  textMessage:string = "test";

  ngOnInit(): void {
    this.textMessage = this.message.textMessage;

    if(this.message.textColorType === "error") {
      this.textColor = "red";
    }
    else if(this.message.textColorType === "success") {
      this.textColor = "#147165";
    }
  }

}
