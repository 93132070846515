<div class="reset-password-wrapper"> 

    <div class="reset-password-body">
    
        <mat-card class="reset-password-card">
            <mat-card-header class="reset-password-header">
              <mat-card-title> <span>MBODY</span> Reset Your Password</mat-card-title>
              <img src="../../../../assets/images/auth/lock_icon_dark_mbody.svg" width="80" height="90" />
              <div *ngIf="successMessage" class="success-message">
                 {{ successMessage }}
              </div>
            </mat-card-header>
           

            <mat-form-field appearance="outline">
                <mat-label>Password</mat-label>
                <input matInput [formControl]="password1FormControl" id="new-password-text-field" autocomplete="new-password" [type]="passwordHide ? 'password' : 'text'">
                <button mat-icon-button matSuffix (click)="passwordHide = !passwordHide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passwordHide">
                  <mat-icon>{{passwordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error *ngIf="password1FormControl.hasError('required')">
                    Password is <strong>required</strong>
                </mat-error>
                <mat-error *ngIf="password1FormControl.hasError('minlength')">
                    Password is <strong>less than {{password1FormControl.getError('minlength')["requiredLength"]}} characters</strong>
                </mat-error>
                <mat-error *ngIf="password1FormControl.hasError('minlength')">
                    {{ password1FormControl.getError('minlength')["requiredLength"] - password1FormControl.getError('minlength')["actualLength"] }}<strong> characters left </strong>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Confirm Password</mat-label>
                <input matInput [formControl]="password2FormControl" id="confirm-password-text-field" autocomplete="new-password" [type]="passwordHide ? 'password' : 'text'">
                <button mat-icon-button matSuffix (click)="passwordHide = !passwordHide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passwordHide">
                  <mat-icon>{{passwordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error *ngIf="password2FormControl.hasError('required')">
                    Password is <strong>required</strong>
                </mat-error>
                <mat-error *ngIf="password2FormControl.hasError('minlength')">
                    Password is <strong>less than {{password2FormControl.getError('minlength')["requiredLength"]}} characters</strong>
                </mat-error>
                <mat-error *ngIf="password2FormControl.hasError('minlength')">
                    {{ password2FormControl.getError('minlength')["requiredLength"] - password2FormControl.getError('minlength')["actualLength"] }}<strong> characters left </strong>
                </mat-error>
            </mat-form-field>

            <button mat-raised-button color="primary" (click)="resetPassword()">Reset Password</button>
            
            <div class="reset-password-footer">

                <div class="footer-links">
                    <a routerLink="../login/"> <h5> Login |</h5>  </a>
                    <a href="https://www.mbody.health/privacy" > <h5> Privacy Policy |</h5>  </a>
                    <a href="https://www.mbody.health/help" > <h5> Contact us </h5>  </a>
                </div>
            </div>

        </mat-card>
    </div>

</div>