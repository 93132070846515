<div class="reset-password-wrapper"> 

    <div class="reset-password-body">
    
        <mat-card class="reset-password-card">
            <mat-card-header class="reset-password-header">
              <mat-card-title> <span>MBODY</span> Reset Password</mat-card-title>
              <img src="../../../../assets/images/auth/lock_icon_dark_mbody.svg" width="80" height="90" />
              <div *ngIf="successMessage" class="success-message">
                 {{ successMessage }}
              </div>
            </mat-card-header>
           

            <mat-card-content class="reset-password-fields">
                <mat-form-field appearance="outline">
                    <mat-label>Email Address</mat-label>
                    <input matInput [formControl]="emailFormControl" type="email">

                    <mat-error *ngIf="emailFormControl.hasError('required')">
                        Email is <strong>required</strong>
                    </mat-error>

                    <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                        Please enter a valid email address
                    </mat-error>
                      
                </mat-form-field>
            </mat-card-content>

            <button mat-raised-button color="primary" (click)="resetPassword()">Reset Password</button>
            
            <div class="reset-password-footer">

                <div class="footer-links">
                    <a routerLink="../login/"> <h5> Login |</h5>  </a>
                    <a href="https://www.mbody.health/privacy" > <h5> Privacy Policy |</h5>  </a>
                    <a href="https://www.mbody.health/help" > <h5> Contact us </h5>  </a>
                </div>
            </div>

        </mat-card>
    </div>

</div>