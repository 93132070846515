<div class="dashboard-wrapper"> 

    <aside class="dashboard-sidenav">
        <div class="sidenav-logo">
            <a routerLink="home"> <img src="../../../../assets/images/logo/MBODY_Logo.png"> </a>
        </div>
        <div class="sidenav">
            <div class="menu-item" routerLink="home" routerLinkActive="active">
                <div class="menu-icon"> 
                    <mat-icon>home</mat-icon>
                </div>
                <div class="menu-text"> 
                    Home
                </div>
            </div>

            <div class="menu-item" routerLink="account" routerLinkActive="active">
                <div class="menu-icon"> 
                    <mat-icon>account_circle</mat-icon>
                </div>
                <div class="menu-text"> 
                    Account
                </div>
            </div>
        </div>
        <div class="sidenav-footer">
            <a href="https://www.mbody.health/privacy"> <h3> Privacy Policy </h3> </a>
            logged in as {{ profile?.email }}
        </div>
    </aside>

    <!-- Only displays on mobile -->
    <div class="dashboard-mobile-toolbar">
        
        <div>
            <button mat-icon-button>
                <mat-icon (click)="toggleMobileSideNav()">menu</mat-icon>
            </button>
        </div>
        <div class="mobile-toolbar-logo">
            <div>
                <a routerLink="home"> <img src="../../../../assets/images/logo/MBODY_Logo.png"> </a>
            </div>
        </div>

    </div>

    <!-- Only displays on mobile -->
    <div [@openClose]="isOpen ? 'open' : 'closed'" [class.hide-div]='!isOpen'> 
        <div class="mobile-sidenav">
            <button mat-icon-button class="close-icon">
                <mat-icon (click)="toggleMobileSideNav()">close</mat-icon>
            </button>
            <div class="menu-item" routerLink="home" (click)="toggleMobileSideNav()" routerLinkActive="active">
                <div class="menu-icon"> 
                    <mat-icon>home</mat-icon>
                </div>
                <div class="menu-text"> 
                    Home
                </div>
            </div>

            <div class="menu-item" routerLink="account" (click)="toggleMobileSideNav()" routerLinkActive="active">
                <div class="menu-icon"> 
                    <mat-icon>account_circle</mat-icon>
                </div>
                <div class="menu-text"> 
                    Account
                </div>
            </div>
        </div>
        <div class="mobile-sidenav-footer">
            <mat-divider></mat-divider>
            <button mat-menu-item class="mobile-sidenav-logout" (click)="logout()">
                <mat-icon>power_settings_new</mat-icon>
                <span>Logout</span>
            </button>
            <mat-divider></mat-divider>

            <div class="mobile-footer-privacy">
                <a href="https://www.mbody.health/privacy"> <h3> Privacy Policy </h3> </a>
                logged in as {{ profile?.email }}
            </div>
            
        </div>
      
    </div>

    
    <main class="dashboard-main">
        <div class="dashboard-header">
            <div class="header-intro"> 
                Hello, {{profile?.firstName}}!
            </div>
            
            <div class="header-menu"> 
                <button mat-icon-button [matMenuTriggerFor]="headerMenu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #headerMenu="matMenu">
                    <button mat-menu-item (click)="logout()">
                        <mat-icon>power_settings_new</mat-icon>
                        <span>Logout</span>
                    </button>
                </mat-menu>
            </div>
        </div>

        <div *ngIf="profile" class="dashboard-content">
            <router-outlet></router-outlet>
        </div>
    </main>

</div>
