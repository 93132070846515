import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatChip } from '@angular/material/chips';

export interface Options {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-form-question',
  templateUrl: './form-question.component.html',
  styleUrls: ['./form-question.component.scss']
})
export class FormQuestionComponent implements OnInit {

  formOptions: {[key:number]:any} = {};
  selectedValues: string[] = [];
  @Input() formItems: Options[] = [];
  @Input() formStyle: {[key:string]:string} = {};
  @Input() formSelectionType: string = "single";

  @Output() selectionChangeEvent: EventEmitter<string[]> = new EventEmitter<string[]>();

  constructor() {}

  ngOnInit(): void {
    for (let i = 0; i < Object.keys(this.formItems).length; i++){
      this.formOptions[i] = {isSelected:false, value:this.formItems[i].value, viewValue:this.formItems[i].viewValue};
    }
  }

  ngOnChanges() {
    /**********THIS FUNCTION WILL TRIGGER WHEN PARENT COMPONENT UPDATES 'someInput'**************/
    this.formOptions = {};

    // clear all previous selections
    for (let i = 0; i < Object.keys(this.formOptions).length; i++){
      delete this.formOptions[i];
    }

    for (let i = 0; i < Object.keys(this.formItems).length; i++){
      this.formOptions[i] = {isSelected:false, value:this.formItems[i].value, viewValue:this.formItems[i].viewValue};
    }
    // console.log("options",Object.keys(this.formOptions).length)
    // console.log("input", this.formItems.length)
  }   

  isOptionSelected(optionIndex:number) {
    return this.formOptions[optionIndex]["isSelected"]
  }

  getFormDirectionStyle() {
    return this.formStyle["direction"]
  }

  toggleSelection(elementIndex:number) {
    // If the form type is single selection
    if (!this.isMultipleSelection()) {
      let optionState = this.formOptions[elementIndex]["isSelected"]
      this.clearSelections()
      this.formOptions[elementIndex]["isSelected"] = !optionState
    }
    else {
      this.formOptions[elementIndex]["isSelected"] = !this.formOptions[elementIndex]["isSelected"]
    }

    this.updateSelectedValues();
  }

  updateSelectedValues():any {
    this.selectedValues = []
    for( const option in this.formOptions) {
      // If the option is selected, add it to the list
      if (this.formOptions[option]["isSelected"]) {
        this.selectedValues.push(this.formItems[option]["value"])
      }
    }
    this.selectionChangeEvent.emit(this.selectedValues)
  }

  isMultipleSelection():boolean {
    return this.formSelectionType === "multiple" ? true : false;
  }

  clearSelections():void {
    for( const option in this.formOptions) {
      this.formOptions[option]["isSelected"] = false
    }
  }

}
