import { Component, OnInit, Output, EventEmitter } from '@angular/core';

interface SelectOption {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {

  selectedMonthValue = "Month";
  selectedYearValue = "Year";

  @Output() monthChangeEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() yearChangeEvent: EventEmitter<string> = new EventEmitter<string>();

  months: SelectOption[] = [
    {value: '01', viewValue: 'Jan'},
    {value: '02', viewValue: 'Feb'},
    {value: '03', viewValue: 'Mar'},
    {value: '04', viewValue: 'Apr'},
    {value: '05', viewValue: 'May'},
    {value: '06', viewValue: 'Jun'},
    {value: '07', viewValue: 'Jul'},
    {value: '08', viewValue: 'Aug'},
    {value: '09', viewValue: 'Sep'},
    {value: '10', viewValue: 'Oct'},
    {value: '11', viewValue: 'Nov'},
    {value: '12', viewValue: 'Dec'},
  ];

  years: SelectOption[] = this.populateYearOption();
  
  handleMonthChangeEvent(value: string) {
    this.selectedMonthValue = value
    this.monthChangeEvent.emit(value);
  }

  handleYearChangeEvent(value: string) {
    this.selectedYearValue = value
    this.yearChangeEvent.emit(value);
  }

  constructor() { }

  ngOnInit(): void {
  }

  populateYearOption(): any {
    const d = new Date();
    const currentYear = d.getFullYear();  
    
    let yearOptions:SelectOption[] = [];

    let firstDate = 1930;
    let lastDate = currentYear - 18;

    for ( firstDate; firstDate <= lastDate; firstDate++) {
      yearOptions.push({value: firstDate.toString(), viewValue: firstDate.toString()},)
    }

    return yearOptions;
  }
}
