import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import * as fromComponents from './components';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ResetPasswordConfirmComponent } from './components/reset-password-confirm/reset-password-confirm.component';

@NgModule({
  declarations: [
    ...fromComponents.components,
    ResetPasswordComponent,
    ResetPasswordConfirmComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    SharedModule
  ],
  exports:[
  ],
  providers:[HttpClientModule, HttpClient]
})
export class AuthModule { }
