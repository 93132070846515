import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { SnackBarMessageComponent } from 'src/app/shared/components';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  passwordHide = true;
  emailFormControl = new FormControl('', [Validators.required, Validators.email]);
  passwordFormControl = new FormControl('', [Validators.required, Validators.minLength(8)]);
  
  constructor(private authService:AuthService, 
              private router: Router,
              private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  logIn(): void {

    if (this.isEmptyFields()) {
      this.displayMessageSnackBar("Please fill the required fields above", "error");
    }
    else if (this.isValidFields()) {
      let email = this.emailFormControl.value;
      let password = this.passwordFormControl.value;

      this.authService.login(email, password).subscribe({
        next: data => {
          let token = data["key"]
          this.authService.saveToken(token)
          this.router.navigate(['/application/home'])
        },
        error: res => {
          if (res.status === 400) {
            this.displayMessageSnackBar(res.error.non_field_errors, "error");
          }
          else {
            this.displayMessageSnackBar("Issue processing the request. Please try again!", "error");
          }
        }
      });
    }
    else {
      this.displayMessageSnackBar("Please correct the above fields", "error");
    }

  }

  isValidFields():boolean {
    let emailStatus = this.emailFormControl.status === "VALID" ? true : false;
    let passwordStatus = this.passwordFormControl.status === "VALID" ? true : false; 

    return emailStatus && passwordStatus
  }

  isEmptyFields():boolean {
    let emailValue = this.emailFormControl.value === "" ? true : false;
    let passwordValue = this.passwordFormControl.value === "" ? true : false; 

    return emailValue && passwordValue
  }

  displayMessageSnackBar(textMessage:string, textColorType:string) {
    this.snackBar.openFromComponent(SnackBarMessageComponent, {duration: 5000, data:{textMessage, textColorType}});
  }
  
}
