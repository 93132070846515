import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { SnackBarMessageComponent } from 'src/app/shared/components';

@Component({
  selector: 'app-reset-password-confirm',
  templateUrl: './reset-password-confirm.component.html',
  styleUrls: ['./reset-password-confirm.component.scss']
})
export class ResetPasswordConfirmComponent implements OnInit {

  successMessage!:string;
  passwordResetToken!:string;
  uid!:string;

  passwordHide = true;
  password1FormControl = new FormControl('', [Validators.required, Validators.minLength(8)]);
  password2FormControl = new FormControl('', [Validators.required, Validators.minLength(8)]);
  
  constructor(private authService:AuthService, 
              private router: Router,
              private route: ActivatedRoute,
              private snackBar: MatSnackBar) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.passwordResetToken = params['token'];
      this.uid = params['uid'];
    });

  }

  resetPassword(): void {

    if (this.isEmptyFields()) {
      this.displayMessageSnackBar("Please fill the required fields above", "error");
    }
    else if (!this.isMatchingPassword()) {
      this.displayMessageSnackBar("Passwords are not matching", "error");
    }
    else if (this.isValidFields()) {
      let password1 = this.password1FormControl.value;
      let password2 = this.password2FormControl.value;

      this.authService.resetPasswordConfirm(password1,password2,this.passwordResetToken,this.uid).subscribe({
        next: data => {
          this.successMessage = "Your password has been reset successfully, please login to continue"
          this.displayMessageSnackBar("Your password has been reset successfully", "success");
          this.router.navigate(['/auth/login']);
        },
        error: resp => {
          console.log(resp)
          if(resp.error["uid"]){
            this.displayMessageSnackBar("Invalid User Token!", "error");
          }
          else if (resp.error["token"]) {
            this.displayMessageSnackBar("Invalid Token!", "error");
          }
          else {
            this.displayMessageSnackBar("Ther's an issue with the request, please try again later", "error");
          }
        }
      });
    }
    else {
      this.displayMessageSnackBar("Please correct the above fields", "error");
    }

  }

  isValidFields():boolean {
    let password1Status = this.password1FormControl.valid;
    let password2Status =  this.password2FormControl.valid;

    return password1Status && password2Status
  }

  isMatchingPassword():boolean {
    let password1 = this.password1FormControl.value;
    let password2 = this.password2FormControl.value;

    let matchingPasswords = password1 === password2;
    return matchingPasswords
  }

  isEmptyFields():boolean {
    let password1Value = this.password1FormControl.value === "";
    let password2Value = this.password2FormControl.value === "";

    return password1Value && password1Value
  }

  displayMessageSnackBar(textMessage:string, textColorType:string) {
    this.snackBar.openFromComponent(SnackBarMessageComponent, {duration: 5000, data:{textMessage, textColorType}});
  }
}
