export class UserProfile {
  
    constructor(
      public profileID:number,
      public userID:number,
      public email:string,
      public premiumEnabled:boolean,
      public firstName:string,
      public birthDate:string){}
    
}

export class StripeSubscription {
  
  constructor(
      public subscription_id:string,
      public currency:string,
      public price:number,
      public status:string,
      public period_end:string,
      public period_start:string,
      public plan:string,
      public trial_start:string,
      public trial_end:string,
      public cancel_at:string,
  ){}
  
}